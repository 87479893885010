import React, { useEffect, useState } from "react";
import { Form, DatePicker, Button, message } from "antd";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import axios from "axios";
import conf from "../config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../context/Usecontext";
const { YearPicker } = DatePicker; // Year picker (using DatePicker in year mode)

const AddNewYear = ({ setIslogin }) => {
  const { currentYear, setCurrentYear } = useMyContext(); // State for the selected year
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = async (values) => {
    try {
      const selectedYear = values.year.format("YYYY"); // Extract year as a string
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.post(
        `${conf.baseurl}/update-current-year`,
        { year: selectedYear },
        config
      );

      if (response.status === 200) {
        // console.log(response.data);
        await getCurrentYear();
        message.success(`Year ${selectedYear} set as current year!`);
        form.resetFields(); // Reset form after submission
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error fetching data:", error.message);
      }
    }
  };

  const getCurrentYear = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/get-current-year`,
        config
      );

      if (response.status === 200) {
        // console.log(response.data);
        setCurrentYear(response.data.currentYear);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error fetching data:", error.message);
      }
    }
  };

  useEffect(() => {
    getCurrentYear();
  }, [currentYear]);

  return (
    <div style={{ maxWidth: 400, margin: "auto", padding: 20 }}>
      <h2>Select a Year</h2>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Choose Year"
          name="year"
          rules={[{ required: true, message: "Please select a year" }]}
        >
          <DatePicker
            picker="year"
            placeholder="Select Year"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Set as Current Year
          </Button>
        </Form.Item>
      </Form>

      {currentYear && (
        <h3 style={{ textAlign: "center", marginTop: 20 }}>
          Current Year: {currentYear}
        </h3>
      )}
    </div>
  );
};

export default AddNewYear;
