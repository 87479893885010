import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Table, Space, Spin, Modal, Tooltip, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import conf from "../config";
import Cookies from "js-cookie";
import { useMyContext } from "../context/Usecontext";
import { MdAddCircleOutline } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { FaArchive } from "react-icons/fa";
import { GoArchive } from "react-icons/go";
const Promotetablelist = ({ setIslogin }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { promotedataforedit, setPromotedataforedit } = useMyContext();
  const [showmodal, setShowmodal] = useState(false);
  const [fundid, setFundId] = useState();

  const getdata = async () => {
    try {
      setLoading(true);
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/getpromotedata`,
        config
      );
      if (response.data.status == "F") {
        // console.log(response.data);
        console.log("no data available");
      } else {
        // console.log(response.data);
        // console.log(response.data.data);
        setData(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        setLoading(false);
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        setLoading(false);
        console.log("Error fetching data:", error.message);
      }
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const deletefund = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const id = fundid;
      console.log(id);
      const response = await axios.delete(
        `${conf.baseurl}/deletefund/${id}`,
        config
      );
      if (response.data.status === "S") {
        toast.success(response.data.message);
        setShowmodal(false);
        getdata();
        setFundId();
      } else {
        toast.error(response.data.message);
        setShowmodal(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error in deleting data:", error.message);
      }
    }
  };
  const columns = [
    {
      title: "Fund",
      dataIndex: "Fund",
      key: "Fund",
    },
    {
      title: "Total Projected Promote Amount",
      dataIndex: "Amount",
      key: "Amount",
      render: (text) => {
        // Ensure the text is a number before formatting
        const amount = Number(text);
        // Format the number using .toLocaleString('en-US')
        const formattedAmount = amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return formattedAmount;
      },
    },
    {
      title: "BVCM %",
      dataIndex: "BVCM %",
      key: "BVCM %",
    },
    {
      title: "Start (Final Close)",
      dataIndex: "Start",
      key: "Start",
    },
    {
      title: "End of Vesting",
      dataIndex: "End",
      key: "End",
    },

    {
      title: "Status",
      dataIndex: "isDeleted",
      key: "Status",
      render: (isDeleted) => (isDeleted ? "Inactive" : "Active"),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit Promote data" color="green">
            <Button type="primary" onClick={() => getdataforedit(record)}>
              <FiEdit />
            </Button>
          </Tooltip>

          <Tooltip
            title={record.isDeleted ? "Unarchive Record" : "Archive Record"}
            color={record.isDeleted ? "blue" : "orange"}
          >
            <Button
              type="primary"
              onClick={() => {
                setFundId(record._id);
                setShowmodal(true);
              }}
            >
              {record.isDeleted ? <FaArchive /> : <GoArchive />}
            </Button>
          </Tooltip>

          <Tooltip title="Toggle override end year">
            <Switch
              checked={record.isShow} // Assuming you have `isActive` property in your record
              onChange={(checked) => handleToggle(record._id, checked)} // Function to handle the toggle change
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              size="small"
              style={{ width: 40 }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleToggle = async (id, checked) => {
    console.log(id, checked);
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${conf.baseurl}/setfund/${id}`,
        { isActive: checked },
        config
      );
      if (response.data.status === "S") {
        toast.success(response.data.message);
        getdata();
      } else {
        toast.error(response.data.message);
        setShowmodal(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error in deleting data:", error.message);
      }
    }
  };

  const getdataforedit = (record) => {
    setPromotedataforedit(record);
    navigate("/editpromotedata");
  };

  const handleCancel = () => {
    setShowmodal(false);
    setFundId();
  };
  const totalAmount = data.reduce(
    (sum, record) => sum + Number(record.Amount),
    0
  );
  return (
    <div className="container">
      <Spin spinning={loading}>
        <div className="formWrapper">
          <div>
            <Button
              className="defaultBtns"
              onClick={() => {
                navigate("/editpromotedata");
                setPromotedataforedit(null);
              }}
              style={{ marginLeft: 10 }}
            >
              <MdAddCircleOutline />
              Add Fund
            </Button>
          </div>
          <Table
            columns={columns}
            bordered={true}
            dataSource={data}
            style={{ overflow: "auto", margin: 10 }}
            pagination={false}
            align="center"
            className="grid-table"
            footer={() => (
              <div style={{ textAlign: "right", fontWeight: "bold" }}>
                Total: $
                {totalAmount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            )}
          />
        </div>
        <Modal open={showmodal} onOk={deletefund} onCancel={handleCancel}>
          <p>Are you sure you want to update the status ?</p>
        </Modal>
      </Spin>
    </div>
  );
};
export default Promotetablelist;
