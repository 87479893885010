import React, { createContext, useContext, useState } from "react";

// Create a context with an initial state
const MyContext = createContext();

// Custom hook to use the context value
export const useMyContext = () => useContext(MyContext);

// Context provider component
export const MyContextProvider = ({ children }) => {
  const [downloading, setDownloading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(null);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [dataforedit, setDataforedit] = useState(null);
  const [promotedataforedit, setPromotedataforedit] = useState(null);
  const [empidforedit, setEmpidforedit] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);

  return (
    <MyContext.Provider
      value={{
        downloading,
        setDownloading,
        isAdmin,
        setIsAdmin,
        fname,
        setFname,
        lname,
        setLname,
        dataforedit,
        setDataforedit,
        promotedataforedit,
        setPromotedataforedit,
        empidforedit,
        setEmpidforedit,
        currentYear,
        setCurrentYear,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};
