import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import React, { useEffect, useState } from "react";
import { useMyContext } from "../context/Usecontext";
import { FaArrowDown } from "react-icons/fa6";
import axios from "axios";
import conf from "../config";
import { Button, DatePicker, Flex, Select, Spin } from "antd";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";

const Statuspage = ({ setIslogin }) => {
  const navigate = useNavigate();
  const { downloading, setDownloading } = useMyContext();
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [zipbtnshow, setZipbtnshow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingzip, setLoadingzip] = useState(false);
  const [zipinfo, setZipinfo] = useState(null);
  const [filterdata, setFilterData] = useState([]);
  const [selectedfilter, setSelectedFilter] = useState("");
  const [selectedvalue, setSelectedvalue] = useState("");
  const [year, setyear] = useState("");
  const { currentYear, setCurrentYear } = useMyContext();

  const fetchProgress = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.get(`${conf.baseurl}/progress`, config);
      // console.log(response);
      if (response.data) {
        const data = response.data;
        if (data.status === 100) {
          setTimeout(() => {
            setProgress(data.status);
            setDownloading(false);
            setZipbtnshow(true);
            fetchzipinfo();
            setSelectedFilter("");
            setSelectedvalue("");
          }, 20000);
        } else {
          // console.log(data);
          setProgress(data.status);
        }
      } else {
        console.error("Failed to fetch progress:", response.statusText);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.error("Error fetching progress:", error);
        console.error("Error:", error.message);
      }
    }
  };
  useEffect(() => {
    let progressInterval;
    getCurrentYear();
    if (downloading) {
      progressInterval = setInterval(fetchProgress, 7000); // Fetch progress every 1 second
    } else {
      clearInterval(progressInterval);
    }

    return () => clearInterval(progressInterval);
  }, [downloading]);

  const getCurrentYear = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/get-current-year`,
        config
      );

      if (response.status === 200) {
        console.log(response.data);
        setCurrentYear(response.data.currentYear);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error fetching data:", error.message);
      }
    }
  };

  const fetchProgressatstart = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(`${conf.baseurl}/progress`, config);

      if (response.data) {
        const data = response.data;
        // console.log(data.status);
        if (data.status === 100) {
          setSelectedFilter("");
          setSelectedvalue("");
          // setyear("");
          setProgress(data.status);
          setShow(true);
          setZipbtnshow(true);
          fetchzipinfo();
        } else if (data.status === 0) {
          setShow(false);
        } else {
          setDownloading(true);
          setProgress(data.status);
          setShow(true);
        }
      } else {
        console.error("Failed to fetch progress:", response.statusText);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.error("Error fetching progress:", error);
        console.error("Error:", error.message);
      }
    }
  };

  const fetchzipinfo = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(`${conf.baseurl}/zipinfo`, config);

      if (response.status === 200) {
        // console.log(response.data);
        setZipinfo(response.data[0]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.error("Error fetching progress:", error);
        console.error("Error:", error.message);
      }
    }
  };
  useEffect(() => {
    // console.log("hello");
    fetchProgressatstart();
    fetchzipinfo();
  }, []);

  const handledownloadall = async () => {
    try {
      if (!year) {
        toast.error(`Please select year first.`);
        return; // Prevent API call
      }
      if (
        (selectedfilter === "Manager" || selectedfilter === "Department") &&
        !selectedvalue
      ) {
        // Show toast message
        toast.error(`Please select a ${selectedfilter} first.`);
        return; // Prevent API call
      }

      setShow(true);
      setDownloading(true);
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.post(
        `${conf.baseurl}/generatall`,
        { filter: selectedvalue, selectedfilter: selectedfilter, year: year },
        config
      );

      if (response.data.message === "S") {
        console.log("zip is created");
        // setDownloading(false)
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
        setDownloading(false);
      } else {
        console.error("Error fetching progress:", error);
        console.error("Error:", error.message);
        setDownloading(false);
      }
    }
  };

  const downloadzip = async () => {
    try {
      setLoading(true);
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await fetch(`${conf.baseurl}/downloadzip`, config);
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "pdfs.zip";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up after download
        window.URL.revokeObjectURL(url);
        setProgress(0);
        setShow(false);
        setZipbtnshow(false);
        setLoading(false);
      } else {
        console.error("Failed to download PDFs:", response.statusText);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.error("Error fetching progress:", error);
        console.error("Error:", error.message);
      }
    }
  };

  const downloadexistingzip = async () => {
    try {
      setLoadingzip(true);
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await fetch(`${conf.baseurl}/downloadzip`, config);
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "pdfs.zip";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up after download
        window.URL.revokeObjectURL(url);

        setLoadingzip(false);
      } else {
        toast.error(" Zip File not found");
        console.error("Failed to download PDFs:", response.statusText);
        setLoadingzip(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.error("Error fetching progress:", error);
        console.error("Error:", error.message);
      }
    }
  };

  const getfilterdata = async (value) => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      if (!year) {
        toast.error(`Please select year first.`);
        setSelectedFilter("");
        return; // Prevent API call
      }
      if (value) {
        const response = await axios.post(
          `${conf.baseurl}/filterdata`,
          { filter: value, year: year },
          config
        );
        if (response.status === 200) {
          // console.log(response.data);
          const filteredData = response.data.filter(
            (item) => item !== null && item !== ""
          );
          setFilterData(filteredData);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.error("Error fetching progress:", error);
        console.error("Error:", error.message);
      }
    }
  };
  const { Option } = Select;

  function disableYears(current) {
    const yearLimit = currentYear || dayjs().year(); // Use state if available, else use current system year
    const year = dayjs(current).year();
    return current && year > yearLimit; // Disable years greater than the limit
  }
  // const disableYears = (current) => {
  //   const allowedYears = [2022, 2023, 2024, 2025];
  //   if (!current) return false; // Handle undefined dates
  //   const year = dayjs(current).year();
  //   return !allowedYears.includes(year);
  // };

  return (
    <div className="container">
      <div className="formWrapper">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {zipinfo ? (
              <>
                <h4>Last Generated Report</h4>
                <div>Created By : {zipinfo.createdBy}</div>
                <div>Created At : {zipinfo.createdAt}</div>
                <p>To download zip click the button below</p>
                <Button
                  onClick={downloadexistingzip}
                  disabled={progress === 0 || progress === 100 ? false : true}
                >
                  {loadingzip ? (
                    //    'Downloading...'
                    <span>
                      Downloading..
                      <Spin />
                    </span>
                  ) : (
                    "Download zip"
                  )}
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            <DatePicker
              picker="year"
              disabledDate={disableYears}
              placeholder="Select Year"
              style={{ marginRight: 20 }}
              allowClear={false}
              onChange={(value) => {
                const formatedyear = dayjs(value).format("YYYY");
                setyear(formatedyear);
              }}
              // value={year}
            />
            <Select
              showSearch
              placeholder="Select option to filter reports by"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              value={selectedfilter}
              options={[
                { value: "Manager", label: "By Manager" },
                { value: "Department", label: "By Department" },
                {
                  value: "EPP Promote",
                  label: "Filter reports by who has EPP Promote data",
                },
              ]}
              onChange={(value) => {
                setSelectedFilter(value);
                getfilterdata(value);
              }}
              allowClear
              style={{ width: 300, marginRight: 20 }}
            />
            {selectedfilter !== "EPP Promote" && (
              <Select
                placeholder="Select an option"
                style={{ width: 200 }}
                allowClear
                onChange={(value) => {
                  setSelectedvalue(value);
                  console.log(value);
                }}
                value={selectedvalue}
              >
                {filterdata.map((option, index) => (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "80vh",
            flexDirection: "row",
          }}
        >
          <div style={{ width: "50%" }}>
            <p style={{ fontSize: 18 }}>
              To generate report for all employees click on Generate All Reports
              button. It may take some time to generate all reports. You can see
              the progress of the process on right side of the screen. The
              reports can be downloaded after the process is finished.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Button
              onClick={handledownloadall}
              disabled={show ? true : false}
              style={
                show
                  ? { backgroundColor: "lightgray", color: "gray" }
                  : { backgroundColor: "#007bff", color: "white" }
              }
            >
              Generate All Reports
              <FaArrowDown style={{ marginLeft: 3, marginTop: 3 }} />
            </Button>

            {show && (
              <>
                <div style={{ height: 200, width: 200, marginTop: 20 }}>
                  <CircularProgressbar
                    value={progress}
                    text={`${progress}%`}
                  ></CircularProgressbar>
                </div>
                <Button
                  disabled={zipbtnshow ? false : true}
                  style={
                    zipbtnshow
                      ? {
                          marginTop: 20,
                          backgroundColor: "white",
                          color: "blue",
                        }
                      : {
                          marginTop: 20,
                          backgroundColor: "lightgray",
                          color: "gray",
                        }
                  }
                  onClick={downloadzip}
                >
                  {loading ? (
                    //    'Downloading...'
                    <span>
                      Downloading..
                      <Spin />
                    </span>
                  ) : (
                    "Download zip"
                  )}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statuspage;
