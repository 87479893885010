import axios from "axios";
import React, { useEffect, useState } from "react";
import conf from "../config";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Space,
  Spin,
  Table,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import { useMyContext } from "../context/Usecontext";

export default function Netincomepage({ setIslogin }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { currentYear, setCurrentYear } = useMyContext();
  const getdata = async (year) => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/getnetincometabel/${year}`,
        config
      );
      const data = response.data[0];
      console.log(data);
      form.setFieldsValue({
        // year: dayjs(data?.year) || dayjs(year),
        cohort1: data?.Cohort1 || 1,
        cohort2: data?.Cohort2 || 2,
        cohort5: data?.Cohort5 || 5,
        cohort6: data?.Cohort6 || 6,
        hurdle_income1: data?.Hurdle_Income_1 || null,
        hurdle_income2: data?.Hurdle_Income_2 || null,
        hurdle_income5: data?.Hurdle_Income_5 || null,
        hurdle_income6: data?.Hurdle_Income_6 || null,
        AnnualBVNetIncome: data?.AnnualBVNetIncome || null,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        setLoading(false);
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error in retrieving data:", error.message);
      }
    }
  };

  useEffect(() => {
    getCurrentYear();
    // getdata();
  }, []);

  const getCurrentYear = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/get-current-year`,
        config
      );

      if (response.status === 200) {
        console.log(response.data);
        setCurrentYear(response.data.currentYear);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error fetching data:", error.message);
      }
    }
  };

  const onchangedata = async (values) => {
    try {
      const token = Cookies.get("token");
      // console.log(token);
      const config = {
        headers: {
          Authorization: token,
        },
      };
      // console.log(values);
      const formattedValues = {
        ...values,
        year: values.year ? dayjs(values.year).format("YYYY") : undefined,
      };
      const response = await axios.put(
        `${conf.baseurl}/editnetincomedata`,
        formattedValues,
        config
      );
      toast(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        setLoading(false);
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error in retrieving data:", error.message);
      }
    }
  };

  // const disableYears = (current) => {
  //   const allowedYears = [2022, 2023, 2024, 2025];
  //   if (!current) return false; // Handle undefined dates
  //   const year = dayjs(current).year();
  //   return !allowedYears.includes(year);
  // };

  function disableYears(current) {
    const yearLimit = currentYear || dayjs().year(); // Use state if available, else use current system year
    const year = dayjs(current).year();
    return current && year > yearLimit; // Disable years greater than the limit
  }
  const onchangeyear = (year) => {
    // console.log(year);
    const formatedyear = dayjs(year).format("YYYY");
    getdata(formatedyear);
    const currentYear = form.getFieldValue("year");
    form.resetFields();
    form.setFieldsValue({ year: currentYear });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Form
        form={form}
        className="formWrapper"
        name="net_income_form"
        layout="vertical"
        onFinish={onchangedata}
        style={{ width: 900 }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16} style={{ marginBottom: "16px" }}>
              <Col span={12}>
                <Form.Item
                  label="Year"
                  name="year"
                  rules={[
                    {
                      required: true,
                      message: "Please select a year.",
                    },
                  ]}
                >
                  <DatePicker
                    picker="year"
                    disabledDate={disableYears}
                    placeholder="Select Year"
                    style={{ width: "100%" }}
                    onChange={onchangeyear}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="AnnualBVNetIncome"
              name="AnnualBVNetIncome"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="annualBVNetIncome" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Cohort 1"
              name="cohort1"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Cohort 1" disabled />
            </Form.Item>
            <Form.Item
              label="Cohort 2"
              name="cohort2"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Cohort 2" disabled />
            </Form.Item>
            <Form.Item
              label="Cohort 5"
              name="cohort5"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Cohort 5" disabled />
            </Form.Item>
            <Form.Item
              label="Cohort 6"
              name="cohort6"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Cohort 6" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Hurdle Income 1"
              name="hurdle_income1"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Hurdle Income 1" />
            </Form.Item>
            <Form.Item
              label="Hurdle Income 2"
              name="hurdle_income2"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Hurdle Income 2" />
            </Form.Item>
            <Form.Item
              label="Hurdle Income 5"
              name="hurdle_income5"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Hurdle Income 5" />
            </Form.Item>
            <Form.Item
              label="Hurdle Income 6"
              name="hurdle_income6"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Hurdle Income 6" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
