import { Button, DatePicker, Tag } from "antd";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import conf from "../config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
export default function DisableYears({ setIslogin }) {
  const [selectedYears, setSelectedYears] = useState([]);
  const navigate = useNavigate();
  const removeYear = (year) => {
    setSelectedYears(selectedYears.filter((y) => y !== year));
  };
  const handleYearChange = (date) => {
    if (date) {
      const year = String(date.year());
      // console.log(year);
      // console.log(selectedYears);
      if (!selectedYears.includes(year)) {
        setSelectedYears([...selectedYears, year]);
      }
      // console.log(selectedYears);
    }
  };

  const handleClear = async () => {
    setSelectedYears([]);
    try {
      const token = Cookies.get("token");
      const selectedYears = [];
      // console.log(token);
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.put(
        `${conf.baseurl}/saveyears`,
        { selectedYears },
        config
      );
      //
      if (response.status === 200) {
        toast(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else if (error.response && error.response.status === 403) {
        console.log(error.response);
        toast.error(error.response.data.message);
      } else {
        console.log("Error in retrieving data:", error.message);
      }
    }
  };
  const saveyears = async () => {
    if (selectedYears.length === 0) {
      toast.error("Select the years first");
      return; // Stop execution if no years are selected
    }
    try {
      const token = Cookies.get("token");
      // console.log(token);
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.put(
        `${conf.baseurl}/saveyears`,
        { selectedYears },
        config
      );
      //
      if (response.status === 200) {
        toast(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else if (error.response && error.response.status === 403) {
        console.log(error.response);
        toast.error(error.response.data.message);
      } else {
        console.log("Error in retrieving data:", error.message);
      }
    }
  };

  const getdisableyearsdata = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/getdisableyears`,
        config
      );

      if (response.status === 200) {
        setSelectedYears(response.data.data[0].years);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error fetching data:", error.message);
      }
    }
  };

  useEffect(() => {
    getdisableyearsdata();
  }, []);

  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        className="formWrapper"
      >
        <div style={{ marginBottom: 20 }}>Note: Select the years to lock</div>
        <DatePicker
          picker="year"
          onChange={handleYearChange}
          placeholder="Select Year"
          style={{ width: 300, marginBottom: "16px" }}
        />
        <div style={{ marginTop: "16px" }}>
          <strong>Selected Years:</strong>
          <div style={{ marginTop: "8px" }}>
            {selectedYears.map((year) => (
              <Tag
                key={year}
                closable
                onClose={() => removeYear(year)}
                style={{ marginBottom: "8px" }}
              >
                {year}
              </Tag>
            ))}
          </div>
        </div>
        <div style={{ marginTop: "16px" }}>
          <Button type="primary" onClick={saveyears}>
            Submit
          </Button>
          <Button style={{ marginLeft: "8px" }} onClick={handleClear}>
            Clear All
          </Button>
        </div>
      </div>
    </div>
  );
}
